import React, { Component } from 'react'
import ReactDOM from "react-dom"
import { VscFeedback } from 'react-icons/vsc'
import { FiPhone } from 'react-icons/fi'
import { RiMessage2Line } from 'react-icons/ri'
import Equalizer from 'react-equalizer'
import Carousel from 'nuka-carousel'
import Image1 from '../../images/slider_1.jpg'
import Image2 from '../../images/slider_3.jpg'
import ImageBG from '../../images/background.png'
import Background from '../../images/people-talking-therapy.jpg'

class Home extends Component {

  componentDidMount() { }

  render() {
    var sectionStyle = {
      backgroundImage: `url(${Background})`
    };
    return (
  <div>
    <div class="container-fluid frontIntro" style={ sectionStyle }>
      <div class="row align-items-center">
        <div class="col-lg-5 offset-lg-6">
          <h1>The Dauten Family Center for Bipolar Treatment Innovation</h1>
          <h3>Integrating research and clinical practice</h3>
          <ul>
            <li> Providing high quality care </li>
            <li> Pioneering research </li>
            <li> Educating clinicians, patients, and the community </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid sectionStaff">
      <div class="row align-items-center">
        <div class="col-lg-4 offset-lg-1 col-md-12 offset-md-0 colText">
          <h2>Who We Are</h2>
          <p>We are experts in clinical practice and research. </p>
          <p> We care about our patients, our community, and the field of mental healthcare. </p>
          <p> <em>Our mission:</em> to help those with bipolar and other mood disorders live full, rich lives.</p>
          <p><a class="btn btn-primary" href="about/our-team" role="button">Find out More About Us&nbsp;</a></p>
        </div>
        <div class="col-lg-6 offset-lg-1 col-md-12 offset-md-0 colPhoto"><img src="img/DSC_8519.jpg" class="img-fluid" alt=""/></div>
      </div>
    </div>
    <div class="container-fluid sectionQuote">
      <div class="row align-items-center">
        <div class="col-md-10 offset-md-1">
          <h2 class="display-2">To accomplish our mission, we need <strong>dedication, perseverance, and imagination</strong>. Above all, we want to engage you, our patients, in a collaborative effort towards wellness.</h2>
        </div>
      </div>
    </div>
    <div class="container-fluid sectionTreatment">
      <div class="row align-items-center">
        <div class="col-lg-6 offset-lg-0 col-md-12 offset-md-0 colPhoto"><img src="./img/DSC_8476.jpg" class="img-fluid" alt=""/></div>
        <div class="col-lg-4 offset-lg-1 col-md-12 offset-md-0 colText">
          <h2><strong>FITT-BD</strong> (Focused Integrative Team-based Treatment for Bipolar Disorder)</h2>
          <p>A new approach to treating bipolar disorder:</p>
          <ul>
            <li>Goal-focused</li>
            <li>Skills-based</li>
            <li>Collaborative</li>
          </ul>
          <p> <em>Learn the skills you need to independently manage your condition.</em></p>
          <p><a class="btn btn-primary" href="https://fitt-bd.org/introduction/introduction1" role="button">Read More About FITT-BD&nbsp;</a></p>
        </div>
      </div>
    </div>
    <div class="container-fluid sectionStudy">
      <div class="row align-items-center">
        <div class="col-lg-5 offset-lg-1 order-lg-1 col-md-10 offset-md-1 order-md-2 order-sm-2 order-xs-2 colText">
          <h2>Announcements</h2>
          <p><b>HAIL</b></p>
          <p>Following a successful study pilot in 2023, the DCBI received a grant extension from the National Institute of Aging to proceed with a clinical trial for Healthy Activity Improves Lives (HAIL), a community-based exercise program for ethnically diverse older adults. The team will evaluate the effectiveness of combining the HAIL online platform with the Fit & Strong! program compared to using the Fit & Strong! program alone across four sites in the Greater Boston area.</p>
          {/*<p><a href="our-research/current-projects#COMPARE" class="btn btn-primary" role="button">Learn More About this Study</a></p>*/}
        </div>
        <div class="col-lg-4 offset-lg-1  order-lg-2 col-md-8 offset-md-2 order-md-1 col-sm-8 offset-sm-2 order-sm-1 col-xs-8 offset-xs-2 order-xs-1 study_logo"> <img src="./img/HAIL-Logo-white.png" class="img-fluid" width="500" alt=""/> </div>
      </div>
    </div>
  </div>
    );
  }
}

export default Home;
