import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import ReactPixel from 'react-facebook-pixel'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'
import '../../styles/assets/css/compare/dauten.css'
import '../../styles/assets/css/compare/compare.css'
import { Helmet } from "react-helmet"

class ResearchCompare extends Component {
  constructor() {
      super()
      this.state = {
        language: 'en'
      }
  }

  componentDidMount() {
     ReactPixel.init('1230911777821192');
     ReactPixel.pageView()
  }

  handleEnrollClick = async (e) => {
    window.open("https://redcap.partners.org/redcap/surveys/?s=CJM9F7FJACKYH399")
    ReactPixel.track('enroll_button_1')
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data.IPv4)
    axios({
      method: 'post',
      url: connectNode + 'public/general/count_enroll_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { ip: res.data.IPv4 }
    })
    .then((result) => { })
  }

  handleEnrollClick2 = async (e) => {
    window.open("https://redcap.partners.org/redcap/surveys/?s=CJM9F7FJACKYH399&dcbi=2")
    ReactPixel.track('enroll_button_2')
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data.IPv4)
    axios({
      method: 'post',
      url: connectNode + 'public/general/count_enroll_click',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { ip: res.data.IPv4 }
    })
    .then((result) => { })
  }

  changeLanguage = (language) => {
    this.setState({ language: language })
  }

  render() {
    return (
      <div class="research COMPARE">
      <div class="container-fluid sectHeader">
        <div class="row d-flex align-items-center no-margin">
          <div class="col-md-3 offset-md-1 col-sm-4 offset-sm-1 col-xs-6 offset-xs-3"><img src="/img/CompareLogo_noTag.png" width="600" height="163" alt="COMPARE: Expanding Options for Healthcare"/> </div>
          <div class="col-md-6 offset-md-1 col-sm-5 offset-sm-1 col-xs-8 offset-xs-2 text-sm-end text-center">
            <div className="language_bar">
              <div className={"lang_es "+((this.state.language=='es' && 'active')||(this.state.language=='en' && 'inactive'))}>
                <a onClick={(e)=>this.changeLanguage('es')}>ES</a>
              </div>
              <div className={"lang_en "+((this.state.language=='en' && 'active')||(this.state.language=='es' && 'inactive'))}>
                <a onClick={(e)=>this.changeLanguage('en')}>EN</a>
              </div>
            </div>
            <p>Expanding Options for Mental Health</p>
          </div>
        </div>
      </div>
      <div class="container-fluid sectPeopleStrip">
        <div class="row">
          <div class="col"> <img src="/img/people-strip.jpg" width="2015" height="245" alt=""/> </div>
        </div>
      </div>
      <div class="container-fluid frontIntro">
        {this.state.language == 'en' &&
        <div class="row d-flex align-items-center g-md-5">
          <div class="col-md-3 offset-md-1 colText blocked left_box">
            <h2>Feeling down?</h2>
            <p><em>Feeling down and can’t seem to snap out of it?</em></p>
            <p>You may be suffering from depression. And you are not alone. In the United States, about one out of every 10 adults experiences depression every year. That’s more than 20 million people.</p>
            <h2>The good news:&nbsp;</h2>
            <p>There are lots of ways to help someone with depression feel better. And researchers are working on new treatments all the time.</p>
          </div>
          <div class="col-md-7 offset-md-0 colText">
            <h1 class="brown_txt">The COMPARE study</h1>
            <p class="four_ins brown_txt"><b><i>Atrium Wake Forest Baptist &nbsp; &nbsp; Butler Hospital<br />Hennepin Healthcare &nbsp;&nbsp; Massachusetts General Hospital</i></b></p>
            <p class="brown_txt">Researchers and clinicians from four major health care centers in the U.S. are getting together to study new treatments right now. Our new study is called <strong class="brown_txt">COMPARE</strong>. We want to compare two treatments for depression:</p>
            <ul class="brown_txt">
              <li class="brown_txt">A gentle, easy-to-do form of yoga focused on breathing, stretching, and relaxation. </li>
              <li class="brown_txt">A talk therapy called<em> behavioral activation</em> that helps motivate people to get out and do things that are important to them. </li>
            </ul>
            <p class="brown_txt">If we find out that both of these ways of treating depression work, we’ll have more ways to help people with depression feel better. </p>
            <p class="brown_txt">But to do this, <strong class="brown_txt">we need your help</strong>.</p>
          </div>
        </div>}
        {this.state.language == 'es' &&
        <div class="row d-flex align-items-center g-md-5">
          <div class="col-md-3 offset-md-1 colText blocked left_box">
            <h2>¿Se siente decaído?</h2>
            <p><em>¿Se siente decaído y no consigue recuperarse?</em></p>
            <p>Es posible que sufra depresión. Y no está solo. En los Estados Unidos, aproximadamente uno de cada 10 adultos sufre depresión cada año. Eso es más de 20 millones de personas.</p>
            <h2>¿Las buenas noticias?&nbsp;</h2>
            <p>Existen muchas formas de ayudar a una persona con depresión a sentirse mejor. Y los investigadores trabajan continuamente en nuevos tratamientos.</p>
          </div>
          <div class="col-md-7 offset-md-0 colText">
            <h1 class="brown_txt">El estudio COMPARE</h1>
            <p class="four_ins brown_txt"><b><i>Atrium Wake Forest Baptist &nbsp; &nbsp; Butler Hospital<br />Hennepin Healthcare &nbsp;&nbsp; Massachusetts General Hospital</i></b></p>
            <p class="brown_txt">En este momento, investigadores y médicos clínicos de cuatro importantes centros de atención médica en los EE. UU. se están reuniendo para estudiar nuevos tratamientos. Nuestro estudio se llama COMPARE. Queremos comparar dos tratamientos para la depresión:</p>
            <ul class="brown_txt">
              <li class="brown_txt">Una forma de yoga suave y fácil de practicar que se centra en la respiración, el estiramiento y la relajación.</li>
              <li class="brown_txt">Una terapia de conversación llamada activación conductual que ayuda a motivar a <em>las personas</em> a salir y hacer cosas que son importantes para ellas.</li>
            </ul>
            <p class="brown_txt">Si descubrimos que estos dos métodos de tratamiento para la depresión funcionan, tendremos más formas de ayudar a las personas con depresión a sentirse mejor.</p>
            <p class="brown_txt">Pero para hacerlo, <strong class="brown_txt">necesitamos su ayuda.</strong>.</p>
          </div>
        </div>}
      </div>

      <div class="container-fluid sectionJoinUs">
      <div class="row d-flex">
        <div class="col-lg-5 offset-lg-1 order-lg-1 col-md-10 offset-md-1 order-md-2 order-sm-2 order-xs-2 colText">
          <h2>{this.state.language == 'en' && 'Join Us!'}{this.state.language == 'es' && '¡Únase a nosotros!'}</h2>
          <p>
          {this.state.language == 'en' && 'If you join our study, you can get treatment that may help your depression.'}
          {this.state.language == 'es' && 'Si se une a nuestro estudio, recibirá un tratamiento que puede ayudarlo con su depresión.'}
          </p>
          <p>
          {this.state.language == 'en' && 'At the same time, you may be able to help other people who struggle with depression.'}
          {this.state.language == 'es' && 'Al mismo tiempo, puede ayudar a otras personas que luchan contra la depresión. '}
          </p>
          {this.state.language == 'en' &&
          <p>When you join, you’ll receive either <strong>12 weeks of yoga</strong> classes OR <strong>12 weeks of talk therapy</strong>. (We can’t tell you right now which kind of treatment you’ll have, so you must be open to doing either of them.) </p>}
          {this.state.language == 'es' &&
          <p>Cuando se una, recibirá 12 semanas de clases de yoga O 12 semanas de terapia de conversación. (No podemos indicarle en este momento qué tipo de tratamiento recibirá, por lo que debe estar dispuesto a hacer cualquiera de los dos).</p>}
          {this.state.language == 'en' &&
          <p>Both the yoga classes and the talk therapy will be done on a smartphone, tablet, or computer instead of in person.* You can also get up to $170.00 for answering questions that will  help us see how you’re doing.</p>}
          {this.state.language == 'es' &&
          <p>Tanto las clases de yoga como la terapia de conversación se realizarán a través de un teléfono inteligente, tableta o computadora en lugar de presencialmente.* También puede obtener hasta $170.00 por responder preguntas que nos ayudarán a saber cómo le va.</p>}
          <p><a class="btn btn-primary" id="enroll_1" onClick={(e) => this.handleEnrollClick(e)} role="button">
          {this.state.language == 'en' && 'Click here to enroll'}
          {this.state.language == 'es' && 'ENLACE AL CUESTIONARIO DE PRESELECCIÓN'}
          </a></p>
          <p class="smaller">* We have a limited number of tablets available for loan. Please inquire.</p>
        </div>
        <div class="col-lg-5 offset-lg-1 order-lg-2 col-md-12 offset-md-0 order-md-1 order-sm-1 order-xs-1 colPhoto" >
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
      </div>

      <div class="container-fluid sectionStudy">
        <div class="row align-items-center">
          {this.state.language == 'en' &&
          <div class="col-md-6 offset-md-6 colText">
            <h2>What do I need to do to join?</h2>
            <p>To join the COMPARE study, you must: </p>
            <ul>
              <li>Be 18 years of age or older </li>
              <li>Have symptoms of depression or sad mood</li>
              <li>Be able to read English  (Spanish version coming soon!)</li>
              <li>Live in Massachusetts, Minnesota, North Carolina, or Rhode Island </li>
            </ul>
          </div>}
          {this.state.language == 'es' &&
          <div class="col-md-6 offset-md-6 colText">
            <h2>¿Qué debo hacer para unirme?</h2>
            <p>Para unirse al estudio COMPARE, debe: </p>
            <ul>
              <li>Tener 18 años de edad o más</li>
              <li>Tener síntomas de depresión o tristeza</li>
              <li>Ser capaz de leer en inglés (¡próximamente habrá una versión en español!)</li>
              <li>Vivir en Massachusetts, Rhode Island</li>
            </ul>
          </div>}
        </div>
      </div>
      <div class="container-fluid sectionCalltoAction">
        <div class="row align-items-center">
          {this.state.language == 'en' &&
          <div class="col-lg-3 offset-lg-1 col-md-10 offset-md-1 colText blocked">
            <h2>What happens after you’re done?</h2>
            <p>After the study is over, we will look at all of the information we have to see how much the yoga classes and therapy sessions helped our participants  feel better. We will let you know what we learn. </p>
          </div>}
          {this.state.language == 'es' &&
          <div class="col-lg-3 offset-lg-1 col-md-10 offset-md-1 colText blocked">
            <h2>¿Qué sucede al finalizar?</h2>
            <p>Una vez finalizado el estudio, analizaremos toda la información que tenemos para ver cuánto ayudaron las clases de yoga y las sesiones de terapia a nuestros participantes a sentirse mejor. Le informaremos sobre lo que averigüemos.</p>
          </div>}
          {this.state.language == 'en' &&
          <div class="col-lg-6 offset-lg-1 col-md-10 offset-md-1 colText">
            <h2><img src="/img/person-computer.png"  class="img-fluid float-end" alt=""/>How can I join the study? </h2>
            <p>Just click on the link below. You’ll be asked to answer some questions to make sure that the study is a good fit for you. Then you can continue to enroll. </p>
            <p><a class="btn btn-primary" id="enroll_2" onClick={(e) => this.handleEnrollClick2(e)} role="button" role="button">Click here to enroll</a></p>
            <p class="smaller">If you have questions about the study or how to join, you can call us at
            336-716-7263 (Atrium Wake Forest Baptist, NC), 401-479-369 (Butler Hospital, RI),
            612-219-3024 (Hennepin Healthcare, MN), 617-643-0857 (Massachusetts General Hospital, MA).
            We’ll be happy to tell you more about the study. We can also help you enroll.</p>
          </div>}
          {this.state.language == 'es' &&
          <div class="col-lg-6 offset-lg-1 col-md-10 offset-md-1 colText">
            <h2><img src="/img/person-computer.png"  class="img-fluid float-end" alt=""/>¿Cómo puedo unirme al estudio?</h2>
            <p>Simplemente haga clic en el enlace que aparece a continuación. Se le pedirá que responda algunas preguntas para asegurarse de que el estudio sea adecuado para usted. Luego, podrá seguir inscribiéndose en el estudio.</p>
            <p><a class="btn btn-primary" id="enroll_2" onClick={(e) => this.handleEnrollClick2(e)} role="button">ENLACE AL CUESTIONARIO DE PRESELECCIÓN</a></p>
            <p class="smaller">Si tiene preguntas sobre el estudio o sobre cómo unirse, puede llamarnos al 617-643-0857 (MA), 401-479-369 (RI). Con gusto, le contaremos más sobre el estudio. También podemos ayudarlo a inscribirse.</p>
          </div>}
        </div>
      </div>
      <div class="container-fluid sectionFooter">
        <div class="row align-items-center">
          <div class="col-md-10 offset-md-1">
            <p>&nbsp;<br />
              Copyright © 2007-2022. The General Hospital Corporation. All Rights Reserved. | Image by <a href="https://www.freepik.com/free-photo/medium-shot-women-talking-therapy_13360967.htm" target="_blank">Freepik</a></p>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResearchCompare)

