import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
import { AppBar, Toolbar } from '@material-ui/core'
import { NavDrawer } from "./NavDrawer"

class NavBarFittbd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false
    }
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    })
  }

  onReloadLogoutPages = () => {
    this.props.onLoadLogoutPages()
  }

  onReloadPages = (route) => {
    this.props.onLoadPages(route)
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadLogoutPages()
  }

  handlePage(route) {
    this.onReloadPages(route)
  }

  render() {
    var get_uri = window.location.pathname
    var sep_uri = get_uri.split("/")
    var get_uri2 = sep_uri[2]

    return (
      <div class="subNav d-none d-sm-block">
        <h3>FITT-BD</h3>
        <ul>
          <li><a href="https://fitt-bd.org/introduction/introduction1">Introduction to FITT-BD</a></li>
          <li><a href="https://dautenbipolarcenter.org/fitt-bd/faq">FAQs</a></li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    b_step: state.authReducer.b_step,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadLogoutPages() {
    dispatch(logoutUser())
  },
  onLoadPages(route) {
    //dispatch(updateLocation(route))
    dispatch({
      type: 'UPDATE_LOCATION',
      payload: {route: route},
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBarFittbd)
